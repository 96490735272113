// Filename: Main.tsx
// This file is the main page of the website. 
// It contains the map and the menu bar and routes to App.tsx
import React from 'react';
import MenuBar from './MenuBar';
import Map from '../components/ReactMap';

export default function Main() {


    return (
        <div >
            <MenuBar/>
            <div style={{ flex: 1, position: 'relative' }}>
                <div id='map' style={{ width: '100%', height: '700px' }}>
                    <Map />
                </div>
            </div>
        </div>
      );
    }
    