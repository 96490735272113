import { useMemo, useEffect, useRef, useState } from 'react';
import ReactMapGL, { LngLatBoundsLike, Marker, Popup } from 'react-map-gl';
import Map, { Source, Layer } from 'react-map-gl';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { cellToBoundary, gridDisk, latLngToCell, cellToChildren, polygonToCells } from 'h3-js';
import { Drawer } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import '../styling/Map.css'
import { Line } from 'react-chartjs-2';

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
)

const bounds = [
  [-122.4597, 47.4959], // Southwest coordinates
  [-121.8863, 47.7341], // Northeast coordinates
];

export default function ReactMap() {

  const [popupInfo, setPopupInfo] = useState(); // state for property popup, display vs closed
  const [zoomLevel, setZoomLevel] = useState(9.5); // state for zoom level
  const [selectedHex, setSelectedHex] = useState(); // state for selecting hexagons
  const [drawerOpen, setDrawerOpen] = useState(false); // opens drawer when property popup is clicked
  const [resolution, setResolution] = useState(6); // state for resolution of hexagons
  const [properties, setProperties] = useState([]); // state for property data
  const [selectedProperty, setSelectedProperty] = useState(); // state for selected property
  const [childrenHexagons, setChildrenHexagons] = useState([]);
  const [saleHistory, setSaleHistory] = useState([]); // state for sale history
  // const nestedHexagons = selectedHex ? gridDisk(latLngToCell(...cellToBoundary(selectedHex)[0], 8), 10) : [];

 // querying metadata with hex id from user click to get property data
 const getHexagonMetadata = async (hexId) => {
  console.log('Fetching metadata for hexId:', hexId);
  await fetch(`https://dvnhepjuw6.execute-api.us-west-2.amazonaws.com/Prod/metadata?hex_id=${hexId}`)
    .then(response => response.json())
    .then(data => {
      if (!data.properties || data.properties.length === 0) {
        console.log(`No metadata found for hexId: ${hexId}`);
        setProperties([]);
        setPopupInfo([]);
      } else {
        // Directly use the 'properties' array from the data
        const formattedData = data.properties.map(item => ({
          lat: item.lat,
          lon: item.lon,
          parcel: item.parcel
        }));
        console.log('Formatted data:', formattedData); // Debug log to verify the data structure
        setProperties(formattedData); // Set the formatted array to your state
      }

    })
    .catch(error => {
      console.error('Error getting hexagon metadata:', error);
    });
};

  // fetching property details with parcel id after hexagon click
  const getPropertyDetails = async (parcelId) => {
    console.log('Fetching property details for parcel:', parcelId);
    console.log(`https://dvnhepjuw6.execute-api.us-west-2.amazonaws.com/Prod/popup?parcel=${parcelId}`);

    try {
      const response = await fetch(`https://dvnhepjuw6.execute-api.us-west-2.amazonaws.com/Prod/popup?parcel=${parcelId}`);
      console.log('response:', response);
  
      const data = await response.json();
      if (!Object.keys(data).length || data === null || data === undefined) {
        console.log('No data found for parcel:', parcelId);
        setProperties([]);
        setPopupInfo([]);
        return null;
      }
      console.log('Fetched property details:', data);
      return data; // Return the fetched data for further processing
  
    } catch (error) {
      console.error('Error fetching property details:', error);
      return null; // Return null or appropriate error handling
    }
  };

  const getSaleHistory = async (parcelId) => {

    console.log('Fetching sale history for parcel:', parcelId);

    console.log('https://dvnhepjuw6.execute-api.us-west-2.amazonaws.com/Prod/salehistory?parcel=' + parcelId)
    
    try {
      const response = await fetch(`https://dvnhepjuw6.execute-api.us-west-2.amazonaws.com/Prod/salehistory?parcel=${parcelId}`);
      
      let data = await response.json();
      if (!Object.keys(data).length || data === null || data === undefined) {
        console.log('No data found for parcel:', parcelId);
        setProperties([]);
        return null;
      }
      console.log('Fetched sale history:', data);

      // Sort the sale history data by date in chronological order
      data = data.sort((a, b) => new Date(a.date) - new Date(b.date))
        .filter((sale, index, array) => {
           // Filter out invalid dates
           if (isNaN(new Date(sale.date).getTime())) return false;
           // Remove duplicate date/sale price pairs by checking if the current sale
           // does not match the next sale in terms of both date and price.
           // This also inherently keeps the first occurrence of any duplicates.
           return !array[index + 1] || sale.date !== array[index + 1].date || sale.price !== array[index + 1].price;
        });

      console.log('Sorted, filtered, and deduplicated sale history:', data);
      return data; // Return the processed data for further use

    } catch (error) {
      console.log('here');
      console.error('Error fetching sale history:', error);
      setSaleHistory([]);
      return null; // Return null or appropriate error handling
    }
};

  // filling in the polygon that wraps king county with initial hexagons
  const kingCountyPolygon = [
    [47.7341, -122.4597], // Northwest
    [47.7341, -121.8863], // Northeast
    [47.4959, -121.8863], // Southeast
    [47.4959, -122.4597], // Southwest
  ]

    const [viewState, setViewState] = useState({
        accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
        latitude: 47.65, // King County, WA centered on render
        longitude: -122.18,
        zoom: zoomLevel,
        bounds: bounds,
      });

      const options = {
        scales: {
          y: {
            beginAtZero: true,
            
          },
          x: {
            max: '2024-01-01',
          }
          
        },     
       
      };
      const saleData = {
        labels: saleHistory.map(item => item.date),
        datasets: [
          // First dataset for previous sale prices.
          {
            label: 'Sale Price',
            data: saleHistory.map(item => item.price),
            predictedPrice: saleHistory.map(item => item.predicted_price),
            fill: false,
            backgroundColor: 'rgb(75, 192, 192)',
            borderColor: 'rgba(75, 192, 192, 0.2)',
          },
          // Second dataset for predicted price from model
          {
            label: 'Predicted Price',
            data: saleHistory.map(item => item.predicted_price),
            type: 'line',
            fill: false,
            backgroundColor: 'rgb(192, 75, 192)',
            borderColor: 'rgba(192, 75, 192, 0.2)',
          }
        ],
      };

      const hexagons = polygonToCells(kingCountyPolygon, resolution); 

      const hexagonsGeoJson = useMemo(() => {

        let hexagonsToUse = childrenHexagons.length > 0 ? childrenHexagons : hexagons;

        const features = hexagonsToUse.map(hex => {
          const boundary = cellToBoundary(hex, true).map(coord => [coord[1], coord[0]]);
          // set opacity of each hexagon based on value from 0-1
          const opacity = Math.random() * (0.85 - 0.2) + 0.2;
          // let rateOfChange = [hex, opacity];
          // console.log('rate of change: ' + rateOfChange)

          return {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [boundary.map(([lat, lng]) => [lng, lat])],
            },
            properties: {
              opacity: opacity,
            },
          };
        });
        return { type: 'FeatureCollection', features };
      }, [childrenHexagons]);

      const hexagonToChildren = (hexId) => {
        let children = cellToChildren(hexId, 9);
        
        setChildrenHexagons(children); // Update state with children hexagons
        
      };

      const handleBackToHexagons = () => {
        setSelectedHex(null); // Deselect any selected hexagon
        setChildrenHexagons([]); // Clear any children hexagons
        setResolution(6); // Reset resolution to 6
         // stop propogation
         // remove markers from map
         setProperties([]);
         setPopupInfo(null);
         
        setViewState({
          ...viewState,
          // Optional: Reset the map view to its initial position and zoom level
          latitude: 47.65,
          longitude: -122.18,
          zoom: 9.5,
        });
      };


      const handleHexagonClick = (hexId) => {
        try {
          if(hexId) {
            setSelectedHex(hexId);
            const [lat, lng] = cellToBoundary(hexId)[0];
            
            if(resolution === 6){
              hexagonToChildren(hexId);
              setViewState(prevState => ({
                ...prevState,
                latitude: lat + 0.03, // Adjust the center of the map
                longitude: lng - 0.01, // puts children hexagons in center of screen on click
                zoom: 12,
              }));
            } else if (resolution === 9){
              setViewState(prevState => ({
                ...prevState,
                latitude: lat + 0.00005  , // Adjust the center of the map
                longitude: lng - 0.0015, // puts children hexagons in center of screen on click
                zoom: 15,
              }));
            // openDrawerWithHexInfo();
            getHexagonMetadata(hexId); 
            }
            setResolution(9);
          } else {
            console.log('No hexagon selected');
        }
      } catch (error) {
        console.error('Error selecting hexagon:', error);
      }
    };

      const onMapClick = (event) => {
        setPopupInfo(null); // Close the popup when user clicks on the map
        const { lng, lat } = event.lngLat;
        console.log(`User clicked at longitude: ${lng}, latitude: ${lat}`);
        if (!isNaN(lng) && !isNaN(lat)) { // Check if coordinates are valid numbers
          const hexId = latLngToCell(lat, lng, resolution);
          handleHexagonClick(hexId);
        }
      };


      const markers = properties.map((property) => (
        <Marker
          key={property.parcel}
          longitude={parseFloat(property.lon)}
          latitude={parseFloat(property.lat)}
          anchor="bottom"
          onClick={(e) => {
            e.originalEvent.stopPropagation(); // Prevent map click event from firing
            onMarkerClick(property.parcel);
          }}
        >
          <div className='popup-marker'>
            <FiberManualRecordIcon sx={{ zIndex: '10', color: 'blue', fontSize: '12px' }} />
          </div>
        </Marker>
      ));

      const openDrawerWithHexInfo  = (info) => {
        console.log("trying drawer");
        setDrawerOpen(true);
      };
      
      const onMarkerClick = async (parcelId) => {
        console.log('User clicked on marker with parcel:', parcelId);
        // Directly use the fetched property details
        let propertyDetails = await getPropertyDetails(parcelId);

        // if no property details, no need to query for SaleHistory
        if(propertyDetails){

          let saleHistory = await getSaleHistory(parcelId);
  
          if (propertyDetails && saleHistory) {
            // Directly pass the property object to setPopupInfo
            setPopupInfo(propertyDetails);
            setSaleHistory(saleHistory);
  
            let currentProp = properties.find(property => property.parcel === parcelId);
            setSelectedProperty(currentProp);
          }
          
        } else {
          // Handle the case when there's an error or no data
          setPopupInfo([]);
          setSaleHistory([]);
        }
      };

      return (
        <div> 

        <Map
            {...viewState}
            style={{width: '100vw', height: '100vh'}}
            mapStyle="mapbox://styles/mapbox/streets-v12"
            onClick={onMapClick}
            onMove={evt => setViewState(prevState => ({
              ...prevState,
              latitude: evt.viewState.latitude,
              longitude: evt.viewState.longitude,
              zoom: evt.viewState.zoom,
            }))}
        >

          {resolution > 6 && selectedHex && (
            <button onClick={handleBackToHexagons} style={{
               position: 'absolute', 
               zIndex: 100, 
               left: 10,
               marginTop: '75px',
               backgroundColor: '#f8f9fa',
               border: 'none',
               cursor: 'pointer',
               borderRadius: '5px',
               boxShadow: '0 2px 4px rgba(0,0,0,0.3)',
             }}>
              <CloseIcon />
             </button>
          )}
          {markers}

          {popupInfo && (
            <Popup
              longitude={parseFloat(selectedProperty.lon)}
              latitude={parseFloat(selectedProperty.lat)}
              onClose={() => setPopupInfo(null)}
              closeOnClick={false}
            >
              <div onClick={() => openDrawerWithHexInfo(popupInfo)}> {/* This div now captures the click event */}
                {popupInfo.error  ? (
                  <div> 
                    <h3>Error getting PopupInfo</h3>
                  </div>
                ) : (
                  <div>
                    <h2>{popupInfo[0].address}</h2>
                    <h4>Beds: {popupInfo[0].bedrooms}  |  Baths: {popupInfo[0].bathrooms}</h4> 
                    <h4>Price: ${popupInfo[popupInfo.length - 1].price.toLocaleString()}</h4>
                    {/* ^^  */}
                  </div>
                )}
              </div>
            </Popup>
          )}

          <Source id="hexagons-source" type="geojson" data={hexagonsGeoJson}>
            <Layer
            id="hexagons-layer"
            type="fill"
            paint={{
              'fill-color': '#DC4C4C', // red color
              'fill-opacity': ['get', 'opacity'],
              'fill-outline-color': '#262323',
              }}
            />
          </Source>
        </Map>

        <Drawer
        className='property-drawer'
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        >
        <div style={{ width: 350, padding: 20, position: 'relative' }}>
        <button onClick={() => setDrawerOpen(false)} > <ChevronRightIcon /> </button>          
          {popupInfo && (
            <>
              <h2>Property Details</h2>
              <h3> Price: ${saleHistory[saleHistory.length - 1].price.toLocaleString()}</h3>
              <h3>{popupInfo[0].address} </h3>
              <p> Beds: {popupInfo[0].bedrooms} | Baths: {popupInfo[0].bathrooms}</p>
            </>
          )}
          {saleHistory && saleHistory.length > 0 && (
        <div id='graph-container'> 
        <h2>Sale History</h2>
        <Line data={saleData} options={options} />
        </div>
      )}
        </div>
      </Drawer>

      </div>
    );
  }
