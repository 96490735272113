import React from 'react';
import { useState, MouseEvent, KeyboardEvent } from 'react';
import { AppBar, Toolbar, Typography, InputBase, IconButton, Drawer, Box, List, ListItem, ListItemText } from '@mui/material';
import { alpha, styled } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import pangeonlogo from '../images/pangeon_logo.png';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ReactMapGL, { GeolocateControl } from 'react-map-gl';

// creating theme for search bar
const theme = createTheme();

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.primary.light, 0.35),
    '&:hover': {
    // makes search bar lighter color when hovered over
      backgroundColor: alpha(theme.palette.primary.light, 0.55), 
    },
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(5),
    width: '80%',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(5),
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      width: '100%',
    },
  }));

export default function MenuBar() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
    // Check if the event is a keyboard event before accessing the key property
    if (event.type === 'keydown' && 'key' in event && (event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift') {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerList = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {['Home', 'About', 'Contact'].map((text) => (
          <ListItem button key={text}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  
    return (
        // Theme provider allows us to use colors, spacing and breakpoints
        <ThemeProvider theme={theme}>
        <AppBar position="absolute">
          <Toolbar>
            <IconButton  
            edge="start" 
            color="inherit" 
            aria-label="menu" 
            sx={{ width: '36px', height: '36px', mr: 2, padding: 0 }}
            onClick={toggleDrawer(true)}
            >

              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <img id="colored-image" src={pangeonlogo} alt="Pangeon Logo" height="36px" style={{ marginTop: '10px', filter: 'grayscale(100%) brightness(1000%)' }} />
            </Typography>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="City, Address, Zip"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ width: '36px', height: '36px', mr: 2, padding: 0 }}
            >
              <AccountBoxIcon sx={{ fontSize: 36 }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          anchor={'left'}
          open={drawerOpen}
          onClose={toggleDrawer(false)}
        >
          {drawerList()}
        </Drawer>
      </ThemeProvider>
    );
  }
