// Filename: JsonData.tsx
// This file contains the JSON data for the property info page
// currently uses singular fixed API endpoint, but will be updated to use multiple endpoints
import React, { useEffect } from 'react';
import axios, { AxiosResponse, AxiosError } from 'axios';
import MenuBar from '../pages/MenuBar';
import {Grid} from '@mui/material';
 
// Current API endpoint we are using for example property
const url = 'https://r38r30xm53.execute-api.us-west-2.amazonaws.com/Prod/getvector?option=property&lat=47.22980318&long=-122.06992797';

// interface for JSON input as of 7 Dec 2023, far more data stored in DB
// This is the JSON data structure used for the Demo.
interface YourData {
  address: string;
  bedrooms: string;
  baths: string;
  price: string;
  image: string;

}

export default function JsonData() {
  // store data so it can be used in HTML elements
  const [responseData, setResponseData] = React.useState<YourData[]>();


  useEffect(() => {
    // Check if data has already been loaded
    if (!responseData) {
      // get request to fetch data from API endpoint var_name = url
      axios.get<YourData[]>(url)
        .then((response: AxiosResponse<YourData[]>) => {
          console.log(response.data); 
          // log data to console, inspect element on localhost:3000/PropertyInfo to see
          // if data is being fetched by this call. 
          setResponseData(response.data); // calls setter function to store data in responseData
        })
        // catch potential errors:
        .catch((error: AxiosError) => {
          console.error('Error:', error.response ? error.response.data : error.message);
        });
    }
  }, [responseData]); 
  // Empty ^ dependency array means this effect runs once after the initial render
  // in this case, will run if responseData is changed

  return (
    
    <div>
      {/* Menu bar for navigation, used on Main.tsx as well */}
      <MenuBar />
      <div style={{ paddingTop: '10%', paddingLeft: '5px' }}>
        {/* Container for Displaying all data including image  */}
        {responseData && (
          // Grid container from MUI to display data in a grid
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <img
                src={responseData[0].image}
                alt="Property-Img"
                style={{ width: '100%', height: '400px', borderRadius: '10px' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2} direction="row">
                <Grid item>
                  <h2 style={{ fontSize: '2.2rem', fontWeight: 'bold', fontFamily: 'sans-serif' }}>
                    ${Number(responseData[0].price).toLocaleString()}
                  </h2>
                </Grid>
                <Grid item>
                  <p style={{ fontFamily: 'sans-serif', fontSize: '1.6rem' }}>
                    Bedrooms: {responseData[0].bedrooms}&nbsp;&nbsp;&nbsp;Baths: {responseData[0].baths}
                  </p>
                </Grid>
                <Grid item>
                  <p style={{ fontWeight: 'normal', fontSize: '1.4rem' }}>
                    Address: {responseData[0].address}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
}