// Filename: PropertyInfo.tsx
// This file contains the property info page, but is populated from JsonData.tsx

import React from 'react';
import JsonData from '../components/JsonData';
import '../styling/PropertyInfo.css';


export default function PropertyInfo () {
      


      return (
        <div className="property-info-container">
          <JsonData />
        </div>
      );
    
};

