import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Main from './pages/Main';
import PropertyInfo from './pages/PropertyInfo';
// Structure of JS documentation:

// @return {PromiseLike<string>}
// function ps() {}
// @returns {{ a: string, b: number }} - May use '@returns' as well as '@return'
 
// function ab() {}
// Parameters may be declared in a variety of syntactic forms

//  @param {string}  p1 - A string param.
//  @param {string=} p2 - An optional param (Google Closure syntax)
//  @param {string} [p3] - Another optional param (JSDoc syntax).
//  @param {string} [p4="test"] - An optional param with a default value
//  @returns {string} This is the result

// function stringsStringStrings(p1, p2, p3, p4) {
// }

// End documentation examples
function App() {


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/propertyinfo" element={<PropertyInfo />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
